/*
 * 업무구분: 활동현황
 * 화 면 명: MSPAP505M
 * 화면설명: 가설발행현황 조회
 * 접근권한: 지점장
 * 작 성 일: 2022.07.
 * 작 성 자: 조한결
 */
<template>
  <ur-page-container title="가입설계발행현황" :show-title="true" type="subpage" action-type="search" @action-search-click="fn_Open514Pop">
    <template #frame-header-fixed>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-info-sum">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="sum">총 {{lv_MockData.length}}건</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
    </template>
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
    <!-- NoData 영역 ::start  -->
     <ur-box-container v-if="lv_MockData.length === 0" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span v-if="nodataCd === 1">상세조회 조건을 설정해 주세요.</span>
            <span v-else>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
     </ur-box-container>
    <!-- NoData 영역 ::end  -->
        <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-contract-list">
          <mo-list :list-data="lv_MockData">
            <template #list-item= "{item}">    
              <mo-list-item>
  <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="name txtSkip">{{item.zaPrdtNm}}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box"></div>
                    <div class="txt_right">
                      <span><strong>{{$commonUtil.numberWithCommas(item.zaSmtotPrm)}}</strong></span>
                    </div>
                  </div>
                  <div class="list-item__contents__info inlineBox">
                    <div class="info_box"></div>
                    <div class="right-box">
                      <span class="txtGray txtSkip mw115">계약자 <b>{{item.zaContrNm}}</b></span><em>|</em>
                      <span class="txtGray">발행일 {{$commonUtil.dateDashFormat(item.zaPblDtm)}}</span>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box"></div>
                    <div class="right-box">
                      <span class="txtLine" @click="fn_Open511Pop(item.zaClctCnsltNm, item.zaClctCnsltNo)">{{item.zaClctCnsltNm}} ({{item.zaClctCnsltNo}})</span>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPAP514P from '@/ui/ap/MSPAP514P'
  import MSPAP511P from '@/ui/ap/MSPAP511P'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import Msg from '@/systems/webkit/msg/msg'
  import moment from 'moment'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP505M", 
    screenId: "MSPAP505M",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
      console.log('this.lv_UserInfo',this.lv_UserInfo, this.lv_UserInfo.srvcMngScCd, this.lv_UserInfo.isBMngr)
      this.fn_SearchUserSrvcMngScCd()
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPAP505M')
    },
    beforeDestroy() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_UserInfo: {}, // 사용자 정보
        lv_MockData:[],
        lv_MockDataOrigin: [],
        lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
        lv_HofOrgData: [], // 사업부 목록
        lv_DofOrgData: [], // 지역단 목록
        lv_FofOrgData: [], // 지점 목록
        lv_FofCnsltData: [], // 컨설턴트 목록
        lv_OrgAuth: [], // 지점권한
        stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}], // NextKey 방식의 페이징에서 다음 페이지의 첫번째 항목의 Key값 저장(http.js에 선언된 변수명과 동일한 이름 사용)
        stndKeyVal: '',
        pageRowCnt: 99999, // 한 페이지에 노출되는 데이터 건수(http.js에 선언된 변수명과 동일한 이름 사용)
        lv_popup514: {}, // 상세조회 팝업을 불러오기 위한 객체
        lv_AlertPop: {}, // 알리팝업을 불러오기 위한 객체
        lv_popup511: {}, // 컨설턴트 정보조회 팝업을 불러오기 위한 객체
        lv_SearchData: {}, // 상세조회에서 가져온 데이터를 저장하기위한 객체
        indexOfAM: 0,
        indexOfFS: 0,
        srchPopupShowYn: false,
        nodataCd: 1,
        lv_IsFirst: true
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_GetData
      * 설명       : data 조회
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_GetData (pData) {
        // if (this.stndKeyVal === 'end') return
        let lv_Vm = this
        console.log('pData :', pData)
          lv_Vm.lv_MockData.slice(0)
          lv_Vm.lv_MockData = []
          lv_Vm.lv_MockDataOrigin.slice(0)
          lv_Vm.lv_MockDataOrigin = []
          lv_Vm.stndKeyList.slice(0)
          lv_Vm.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}]
          lv_Vm.stndKeyVal = ''
        if (pData === 'init') {
          let pParams = {
          ZPblProInqrDto: {
          zaHptsFrmScCd: '01', // 상품설명서 구분코드(01: 제안용)
          hofOrgNo: lv_Vm.lv_UserInfo.onpstHofOrgNo, // 사업부 코드
          zPagingDto: {}, // 페이징 객체
          inptYmd: moment(new Date()).format('YYYYMMDD'), // 조회일자
          fofOrgNo:lv_Vm.lv_UserInfo.onpstFofOrgNo, // 지점 코드
          dofOrgNo: lv_Vm.lv_UserInfo.onpstDofNo // 지역단 코드
          }
        }
        if (this.indexOfFS > -1) {
          pParams.ZPblProInqrDto.hofOrgNo = lv_Vm.lv_UserInfo.onpstDofNo
          pParams.ZPblProInqrDto.dofOrgNo = lv_Vm.lv_UserInfo.trtFofOrgNo
        }
        console.log('pParams', pParams)
        pParams.ZPblProInqrDto.zPagingDto.zaFnctScCd = 'S' // 조회구분
        pParams.ZPblProInqrDto.zPagingDto.zaPageRowCnt = lv_Vm.pageRowCnt
        pParams.ZPblProInqrDto.zPagingDto.zaStndkey = lv_Vm.stndKeyList.stndKeyId
        let commHeader = {
            eaiId: 'C088_F1PQL0463_S',
            fahrTrnId: 'S',
            requestSystemCode: 'F1088', //
            reqSrvcNm: '',
            reqMthdNm: '',
            targetSystemCode: '',
            resVONm: 'sli.in.ea2.vo.a0025pq.EAF1PQL0463VO',
            reqVONm: 'sli.in.ea2.vo.a0025pq.EAF1PQL0463VO'
          }
          this.$pmUtil.eaiInvoke(pParams, 'txTSSPM01P1', 'S', commHeader, lv_Vm.fn_PMUtilHandler)
        } else {
          let roleAmGa = {
            ZPblProInqrDto: {
              zaHptsFrmScCd: '01', // 상품설명서 구분코드(01: 제안용)
              hofOrgNo: this.lv_UserInfo.onpstDofNo, // AFC지점장일 경우 사업부 자리에 지역단을 배치한다.
              zPagingDto: {},
              inptYmd: moment(pData.searchDate).format('YYYYMMDD'),  // 조회일자
              fofOrgNo: pData.fofOrg.key,
              dofOrgNo: lv_Vm.lv_UserInfo.onpstFofOrgNo // AFC지점장일 경우 지역단 자리에 지점을 배치한다.
            }
          }
          console.log('roleAmGa', roleAmGa)

          if (this.indexOfAM === -1 && this.indexOfFS === -1) { // AFC지점장, 금융서비스지사장이 아닌 경우
            console.log('this.indexOfAM', this.indexOfAM)
            console.log('this.indexOfAM', roleAmGa.ZPblProInqrDto)
            roleAmGa.ZPblProInqrDto.hofOrgNo = pData.hofOrg.key // 사업부
          roleAmGa.ZPblProInqrDto.dofOrgNo = pData.dofOrg.key // 지역단
          } else if (this.indexOfFS > -1) { // 금융서비스 지사장인 경우
            console.log('this.indexOfFS', this.indexOfFS)
            roleAmGa.ZPblProInqrDto.hofOrgNo = pData.dofOrg.key // 사업부 자리에 지역단 배치
            roleAmGa.ZPblProInqrDto.dofOrgNo = lv_Vm.lv_UserInfo.trtFofOrgNo // 지역단 자리에 지점 배치
          }

        roleAmGa.ZPblProInqrDto.zPagingDto.zaFnctScCd = 'S'
        roleAmGa.ZPblProInqrDto.zPagingDto.zaPageRowCnt = lv_Vm.pageRowCnt
        roleAmGa.ZPblProInqrDto.zPagingDto.zaStndkey = lv_Vm.stndKeyList.stndKeyId
        let commHeader = {
          eaiId: 'C088_F1PQL0463_S',
          fahrTrnId: 'S',
          requestSystemCode: 'F1088', //
          reqSrvcNm: '',
          reqMthdNm: '',
          targetSystemCode: '',
          resVONm: 'sli.in.ea2.vo.a0025pq.EAF1PQL0463VO',
          reqVONm: 'sli.in.ea2.vo.a0025pq.EAF1PQL0463VO'
          }
          this.$pmUtil.eaiInvoke(roleAmGa, 'txTSSPM01P1', 'S', commHeader, lv_Vm.fn_PMUtilHandler)
        }
      },
      /******************************************************************************
      * Function명 : fn_PMUtilHandler
      * 설명       : PM유틸 핸들러
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_PMUtilHandler (response) {
        if (!response || !response.body.zpblProInqrDto.zpiPblProInqrDTO || !response.body) {
          console.log('noting returned')
          this.nodataCd = 2
          return
        }
          // 서버 데이터 가져오기
          if (!_.isEmpty(response) && !_.isEmpty(response.body.zpblProInqrDto.zpiPblProInqrDTO)) {
            let responseList = response.body.zpblProInqrDto.zpiPblProInqrDTO
            console.log('responseList', responseList)
            if (responseList !== null) {
              this.lv_MockData = []
              this.lv_MockDataOrigin = []
              responseList.forEach((item) => {
                this.lv_MockData.push(item)
                this.lv_MockDataOrigin.push(item)
              })
            }
            // 더보기 키값
            this.stndKeyList = []
            if (response.trnstComm.stndKeyList[0]) {
              this.stndKeyList.push(response.trnstComm.stndKeyList[0])
              if (this.stndKeyList === null || this.stndKeyList[0] === null || this.stndKeyList[0].stndKeyVal === null || this.stndKeyList[0].stndKeyVal.trim() === '') {
              // 다음페이지 데이터 존재안함(마지막 페이지)
                this.stndKeyVal = 'end'
              } else {
              // 다음페이지 데이터 존재함
                this.stndKeyVal = 'more'
              }
            }
          } 
        else {
          let message = '[' + response.msgComm.msgCd + ']  ' + response.msgComm.msgDesc
          this.fn_BottomAlert(message)
        }
        console.log('fn_PMUtilHandler', response)
        if (Object.keys(this.lv_SearchData).length !== 0) {
          this.fn_InnerSrch(this.lv_SearchData)
        }
        // this.fn_InnerSrch(this.lv_SearchData)
      },
      /******************************************************************************
      * Function명 : fn_InnerSrch
      * 설명       : 조회키 클릭시 정렬
      * 인자       : N/A
      * Return     : N/A
      ******************************************************************************/
      fn_InnerSrch (pData) {
        let lv_Vm = this
        console.log(pData)
        lv_Vm.lv_MockData.splice(0)
        lv_Vm.lv_MockData = lv_Vm.lv_MockDataOrigin.slice(0, lv_Vm.lv_MockDataOrigin.length)
        if (pData.cnslt.key === '0') {
          lv_Vm.lv_MockData = lv_Vm.lv_MockDataOrigin.slice(0, lv_Vm.lv_MockDataOrigin.length)
        } else {
          console.log('selected 컨설턴트 -> 전체아님 filer', lv_Vm.lv_MockData.zaClctCnsltNo)
          lv_Vm.lv_MockData = lv_Vm.lv_MockData.filter(o => o.zaClctCnsltNo === pData.cnslt.key)
        }
      },
      /******************************************************************************
      * Function명 : fn_moment
      * 설명       : 화면에서 일자 표기
      * 인자       : date
      * Return     : N/A
      ******************************************************************************/
      fn_moment (date) {
        return moment(date).format('YYYY-MM-DD')
      },
      /******************************************************************************
      * Function명 : fn_SearchUserSrvcMngScCd
      * 설명       : 로그인 사용자의 영업채널구분코드 검색
      *              사용자의 영업채널구분코드가 FC(FC, SFP지점장), FS(금융서비스), GP(GFC지점장), SA(WM지점장) 이면 최초화면진입 시 데이터 조회
      *              AM(AFC), GA(GA)이면 대리점 선택을 위한 상세조회팝업 이동 필요
      *              2022-08-10 차민아 프로 요구사항
      *              기존 사랑On권한 기반(F110, A110, G110 ...)으로 설계가 되어 있었으나, 권한 이름만 존재하고 실제 운영계의 역할과 연계가 되어있지 않음을 확인(ex) AFC지점장의 역할권한이 F110, A110 등록)
      *              지점장의 역할이 중복으로 들어갈 수 있으므로 영업채널구분코드를 통하여 소속 채널을 구분하도록 수정.
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_SearchUserSrvcMngScCd () {
        let t_srvcMngScCd = this.lv_UserInfo.srvcMngScCd
        let t_findIdxUserSrvcMngScCd = 'FC,FS,GP,SA'.indexOf(t_srvcMngScCd)
        this.indexOfAM = 'AM'.indexOf(t_srvcMngScCd)
        this.indexOfFS = 'FS'.indexOf(t_srvcMngScCd)
        console.log('t_findIdxUserSrvcMngScCd', t_findIdxUserSrvcMngScCd)
        console.log('this.indexOfAM', this.indexOfAM)
        if (t_findIdxUserSrvcMngScCd !== -1) {
          console.log('최초 화면진입 시 데이터 조회 필요')
          this.fn_GetData('init')
          this.lv_IsFirst = false
        } else {
          this.fn_Init()
          console.log('상세조회팝업 이동 필요')
          this.fn_RoleBottomAlert()
        }
      },
      /******************************************************************************
       * Function명 : fn_BottomAlert
       * 설명       : 확인 팝업 호출
       * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
       ******************************************************************************/
      fn_BottomAlert (contents) {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '알림',
            content: contents
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
    /******************************************************************************
    * Function명 : fn_RoleBottomAlert
    * 설명       : 단순알림 팝업 호출
    ******************************************************************************/
      fn_RoleBottomAlert () {
        this.lv_AlertPopup = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '알림',
            content: '지점(대리점)을 먼저 선택해 주세요.'
          },
          listeners: {
            onPopupConfirm: () => {
              this.fn_Open514Pop()
              this.$bottomModal.close(this.lv_AlertPopup);
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPopup);
            }
          }
        },
        {properties: {preventTouchClose : false}}
        )},
      /******************************************************************************
       * Function명 : fn_Open511Pop
       * 설명       : FC문자/전화 팝업 호출
       * 
       ******************************************************************************/
      fn_Open511Pop (cnsltNm, cnsltNo) {
        this.lv_popup511 = this.$bottomModal.open(MSPAP511P, {
          properties: {
            pCnsltNm: cnsltNm,
            pCnsltNo: cnsltNo,
            FCTelBtn: "전화하기",
            FCSMSBtn: "문자하기"
          },
          listeners: {
            onPopupConfirm: () => {
              console.log("onPopupConfirm")
              this.$bottomModal.close(this.lv_popup511)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_popup511)
            }
          }
        })
      },
      /******************************************************************************
       * Function명 : fn_Open514Pop
       * 설명       : 상세조회조건 팝업 호출
       * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
       ******************************************************************************/
      fn_Open514Pop() {
        console.log('this.lv_SearchData', Object.keys(this.lv_SearchData).length)
        this.srchPopupShowYn = true // 상세조회조건 활성화시 true
        if (Object.keys(this.lv_SearchData).length === 0) {
          this.lv_popup514 = this.$bottomModal.open(MSPAP514P, {
            properties: {
              title: "상세조회조건",
              pSrnId: 'MSPAP505M',
              userInfo: this.getStore('userInfo').getters.getUserInfo,
              pSearchData: this.lv_SearchData
            },
            listeners: {
              onPopupConfirm: (pData) => {
                console.log("onPopUpClose")
                this.$bottomModal.close(this.lv_popup514)
                // pData.cnslt.key = '0' // 테스트를 위한 추가 코드 (삭제해야함)
                this.fn_GetData(pData)
                this.lv_IsFirst = false
                // this.fn_InnerSrch(pData)
                Object.assign(this.lv_SearchData, pData)
                console.log('this.lv_SearchData', this.lv_SearchData)
                
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.lv_popup514)
              }
            }
          })
        } else {
          this.lv_popup514 = this.$bottomModal.open(MSPAP514P, {
            properties: {
              title: "상세조회조건",
              pSrnId: 'MSPAP505M',
              userInfo: this.getStore('userInfo').getters.getUserInfo,
              pSearchData: this.lv_SearchData

            },
            listeners: {
              onPopupConfirm: (pData) => {
                console.log("onPopUpClose")
                this.$bottomModal.close(this.lv_popup514)

                this.fn_GetData(pData)
                this.lv_IsFirst = false
                Object.assign(this.lv_SearchData, pData)
                console.log('this.lv_SearchData', this.lv_SearchData)
                
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.lv_popup514)
              }
            }
          })
        }
      },
      /******************************************************************************
      * Function명 : fn_Init
      * 설명       : 화면 초기화
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_Init () {
        this.lv_MockData.slice(0)
        this.lv_MockData = []
        this.lv_MockDataOrigin.slice(0)
        this.lv_MockDataOrigin = []
        this.stndKeyList.slice(0)
        this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}]
        this.stndKeyVal = ''
      },
      /******************************************************************************
      * Function명 : fn_BackBtnClicked
      * 설명       : 백 버튼 클릭이벤트(Device Back 포함)
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_BackBtnClicked () {
        // 상세조회조건 팝업을 호출한 적이 있으면 뒤로가기 대신 화면을 초기화 한다.
        if (this.srchPopupShowYn) {
          this.lv_SearchData = {}
          this.fn_SearchUserSrvcMngScCd()
          this.srchPopupShowYn = false
          this.lv_IsFirst = true
          this.nodataCd = 1
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
</script>
<style scoped>
</style>